body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  background: url("../src/img/back.png"); */
  background: #000;
  overflow: hidden;
}
/* * {
  overflow: hidden;
} */
.galleryImage {
  max-width: 80%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.thumbs {
  display: flex;
}

.thumbs > img {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
}

.gcontainer {
  display: flex;
  flex-direction: column;
}

.gslide-image img {
  max-height: 60vh !important;
  max-width: 100%;
}

.shareBar {
  width: 100%;
  height: 3rem;
  background-color: rgba(1, 1, 1, 0.85);
}
/* iframe {
  width: 100% !important;
  height: 100vh !important;
}
video {
  position: relative !important;
}
.App:first-child div:nth-child(2),
.App:first-child div:nth-child(2) div {
  background-color: transparent !important;
  touch-action: none;
  pointer-events: none !important;
  display: none;
}
.App img {
  overflow: hidden;
  touch-action: none;
  pointer-events: none !important;
}
iframe body {
  margin: 0 !important;
}
iframe video {
  position: relative;
}
video:-webkit-full-page-media {
  position: relative;
}
@media screen and (max-width: 1080px) {
  .App:first-child div:nth-child(2) {
    overflow: hidden;
    position: relative !important;
    display: block !important;
    height: 0;
    padding: 0 !important;
  }
} */
/* @media screen and (max-height: 1100px) {
  iframe {
    width: 100% !important;
    height: 84.4vh !important;
  }
} */
/* @media screen and (min-width: 375px) and (max-height: 900px) {
  iframe {
    width: 100% !important;
    height: 75vh !important;
  }
}
@media screen and (min-width: 375px) and (max-height: 740px) {
  iframe {
    width: 100% !important;
    height: 84.4vh !important;
  }
}
@media screen and (min-width: 768px) and (max-height: 1366px) {
  iframe {
    width: 100% !important;
    height: 85vh !important;
  }
}
@media screen and (min-width: 768px) and (max-height: 1366px) {
  iframe {
    width: 100% !important;
    height: 85vh !important;
  }
} */
